import React from "react";
import Layout from "../../../../../other/layout";
import EasyStoreMakerPro from "../../../../../components/AC-StaticPages/Developpez/ServicesWeb/SolutionsPourLeCybercommerce/EasyStoreMakerPro/EasyStoreMakerPro";
import SEO from "../../../../../other/seo";

export default function easyStoreMakerPro() {
  return (
    <Layout>
      <SEO title="EasyStoreMaker Pro" />

      <EasyStoreMakerPro />
    </Layout>
  );
}
