import React from "react";
import styles from "./styles.module.css";
import Button from "../../../../components/Button";

export default function EasyStoreMakerPro() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>EasyStoreMaker Pro</h1>
        <p>
          Une vitrine sur le Web vous confère la possibilité d'offrir vos
          produits à n'importe qui, n'importe où et n'importe quand. L'Internet
          a révolutionné le concept même de la mise en marché et de la vente en
          éliminant les barrières géographiques et temporelles entre le
          commerçant et le consommateur. Désormais, sans engager un seul nouvel
          employé, vous pourrez recevoir et traiter des commandes de partout
          dans le monde avec EasyStoreMaker Pro.
        </p>
        <p>
          Avec son assistant de construction convivial, EasyStoreMaker Pro vous
          permet d'intégrer à votre site Web une boutique virtuelle complète.
        </p>
        <h2>Avec EasyStoreMaker Pro, vous pouvez :</h2>
        <ul>
          <li>
            Ajouter un nombre illimité de produits et d'images que vous
            cataloguerez en fonction des catégories que vous avez vous-même
            définies
          </li>
          <li>
            Consulter le vérificateur de stock intégré, qui dressera en temps
            réel l'inventaire de votre boutique
          </li>
          <li>
            Obtenir de l'aide lorsque vous en avez besoin avec son menu
            interactif et le tutoriel Flash
          </li>
          <li>Traiter en ligne les transactions par carte de crédit</li>
          <li>
            Accéder à une page sécurisée où visualiser toutes les commandes
          </li>
        </ul>
        <Button
          text={"Comparer les forfaits"}
          url={
            "/developpez/services-web/solutions-pour-le-cybercommerce/forfaits-cybercommerce/"
          }
        />
      </div>
      <div className={styles.row}>
        <h2>Pourquoi choisir EasyStoreMaker Pro?</h2>
        <div className={styles.column}>
          <h3>Caractéristiques</h3>
          <ul>
            <li>Calcul évolué des taxes applicables dans chaque province</li>
            <li>Page d'état de la boutique</li>
            <li>Aucune connaissance préalable en programmation requise</li>
            <li>Création de catalogues</li>
            <li>
              Possibilité d'appliquer des rabais à l'ensemble des produits en
              magasin ou à des produits particuliers
            </li>
            <li>
              Aucune expérience préalable dans le développement du commerce en
              ligne requise
            </li>
            <li>
              Possibilité d'attribuer une catégorie aux produits (p. ex. :
              nouveaux, en solde)
            </li>
            <li>
              Fonction intégrée de gestion des stocks permettant d'éviter toute
              rupture
            </li>
            <li>Plusieurs images par produit</li>
          </ul>
        </div>
        <div className={styles.column}>
          <h3>Avantages</h3>
          <ul>
            <li>Achats en devises étrangères acceptés</li>
            <li>Visualisation de l'état de votre boutique virtuelle</li>
            <li>Certificat SSL intégré - GRATUIT</li>
            <li>
              Calcul des frais d'expédition en fonction du poids des articles
              commandés
            </li>
            <li>Numéros d'unité de gestion des stocks (UGS) modifiables</li>
            <li>Schéma de couleurs de la vitrine virtuelle personnalisable</li>
            <li>
              Zone sécurisée de traitement des transactions par carte de crédit
            </li>
            <li>
              Possibilité de fixer une quantité maximale ou un nombre maximal de
              commandes pour certains produits
            </li>
            <li>Mise en valeur de vos produits sous différents angles</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
